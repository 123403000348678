















































import { Vue, Component, PropSync } from 'vue-property-decorator';
import SuccessfulAllianzProcessViewModel
  from '@/vue-app/view-models/components/contract-savings/allianz/successful-allianz-process-view-model';

@Component({ name: 'SuccessfulAllianzProcess' })
export default class SuccessfulAllianzProcess extends Vue {
  @PropSync('isOpen', { required: true, type: Boolean })
  private is_open!: boolean;

  successful_allianz_process_view_model = Vue.observable(new SuccessfulAllianzProcessViewModel());
}
